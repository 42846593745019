import { useEffect } from "react";
import { connect } from "react-redux";

import { UndergraduatePortal } from "../../../shared/templates/portal/undergraduate";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { AlertMsg } from "../../../shared/components/alert-msg";
import { LoadingItem } from "../../../shared/components/loading";
import { uGradPortal } from "../../../redux/action/portal/undergraduate";
import { appConstantsUGradOPortal } from "../../../redux/action-constants/portal/undergraduate";
import "./index.scss";
import { Link } from "react-router-dom";

const getUnderGEntrepreneurFeeActionRequest = async ({
  pageProps,
  payload,
}: any) => {
  await pageProps.getUnderGEntrepreneurFee(payload);
};
const PaymentDetails = ({ pageProps }: any) => {
  const screenResolution: any = useWindowSize();
  let requestInfo =
    pageProps.UnderGradEntrepreneurFeeRequest?.request_data?.response;

  return (
    <div className="payment_details">
      <div className="txtn_info_wrap">
        <div className="each_info_detail">
          <div className="info_title">SESSION :</div>
          <div className="info_data">{requestInfo?.session}</div>
        </div>
        <div className="each_info_detail">
          <div className="info_title">TRANSACTION ID :</div>
          <div className="info_data">{requestInfo?.transactionId}</div>
        </div>
        <div className="each_info_detail">
          <div className="info_title">MATRIC NO :</div>
          <div className="info_data">{requestInfo?.matricNumber}</div>
        </div>
        <div className="each_info_detail">
          <div className="info_title">LEVEL :</div>
          <div className="info_data">{requestInfo?.level}</div>
        </div>
        <div className="each_info_detail">
          <div className="info_title">STUDENT NAME :</div>
          <div className="info_data">{requestInfo?.fullName}</div>
        </div>
        <div className="each_info_detail">
          <div className="info_title">SERVICE :</div>
          <div className="info_data">{requestInfo?.paymentType}</div>
        </div>
        <div className="each_info_detail">
          <div className="info_title">TOTAL AMOUNT PAYABLE :</div>
          <div className="info_data">{requestInfo?.amount}</div>
        </div>
        <div className="each_info_detail">
          <div className="info_title">PAYMENT STATUS :</div>
          <div className="info_data">{requestInfo?.paymentStatus}</div>
        </div>
      </div>
      {requestInfo?.paymentStatus !== "PAID" && (
        <div className="txtn_cta">
          <a
            target="_blank"
            rel="noreferrer"
            className="btn"
            href={requestInfo?.downloadUrl}
          >
            Download Invoice
          </a>
        </div>
      )}

      {requestInfo?.compulsoryFeeHistories.length >= 1 && (
        <div className="previous_payments">
          {screenResolution?.width >= 767 && (
            <table>
              <thead>
                <tr>
                  <th className="first">
                    <span> GENERATED ON</span>
                  </th>
                  <th>
                    {" "}
                    <span>SESSION</span>
                  </th>
                  <th>
                    {" "}
                    <span>TRANSACTION ID</span>
                  </th>
                  <th>
                    {" "}
                    <span>PAYMENT STATUS</span>
                  </th>
                  <th>
                    {" "}
                    <span>AMOUNT</span>
                  </th>
                  <th className="last">
                    <span>DOWNLOAD</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {requestInfo?.compulsoryFeeHistories.map(
                  (txtn: any, index: any) => {
                    return (
                      <tr>
                        <td className="first">
                          <span>{txtn?.generatedOn}</span>
                        </td>
                        <td>
                          {" "}
                          <span>{txtn?.session}</span>
                        </td>
                        <td>
                          {" "}
                          <span>{txtn?.transactionId}</span>{" "}
                        </td>
                        <td>
                          {" "}
                          <span
                            className={
                              txtn?.paymentStatus.toLowerCase() === "paid"
                                ? "done"
                                : "pending"
                            }
                          >
                            {txtn?.paymentStatus}
                          </span>{" "}
                        </td>
                        <td>
                          {" "}
                          <span>{txtn?.amount}</span>{" "}
                        </td>
                        <td className="last">
                          <span>
                            {" "}
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={txtn?.downloadUrl}
                              className="download_cta btn"
                            >
                              Download Receipt
                            </a>
                          </span>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          )}

          {screenResolution?.width <= 766 && (
            <div className="past_data">
              {requestInfo?.compulsoryFeeHistories.map(
                (txtn: any, index: any) => {
                  return (
                    <div className="each_history_data">
                      <div className="top_data">
                        <div className="left">
                          {" "}
                          <span>Generated on:</span> {txtn?.generatedOn}
                        </div>
                        <div className="right">
                          {" "}
                          <span
                            className={
                              txtn?.paymentStatus.toLowerCase() === "paid"
                                ? "done status"
                                : "pending status"
                            }
                          >
                            {txtn?.paymentStatus}
                          </span>
                        </div>
                      </div>
                      <div className="other_data">
                        <div className="left">
                          <div className="title">Amount</div>
                          <div className="value">{txtn?.amount}</div>
                        </div>
                        <div className="right">
                          <div className="title">Transaction ID</div>
                          <div className="value">{txtn?.transactionId}</div>
                        </div>
                      </div>
                      <div className="other_data">
                        <div className="left">
                          <div className="title">Session</div>
                          <div className="value">{txtn?.session}</div>
                        </div>
                        <div className="right">
                          <div className="value">
                            <a
                              href={txtn?.downloadUrl}
                              className="download_cta btn"
                            >
                              Download Receipt
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div>
      )}
      <div className="goback_cta">
        <Link to="/undergraduate/dashboard" className="btn">
          Back
        </Link>
      </div>
    </div>
  );
};

const PaymentWrap = ({ pageProps }: any) => {
  let authInfo = pageProps.uGradLoginRequest.request_data;
  let requestInfo = pageProps.UnderGradEntrepreneurFeeRequest;

  useEffect(() => {
    let payload = {
      // matricNumber: "U24/FEA/SED/1015"
      matricNumber: authInfo?.matricNumber,
    };

    getUnderGEntrepreneurFeeActionRequest({
      pageProps,
      payload,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="payments_info_wrap">
      {/* <div className="heading_wrap">
        Transaction Summary For Entrepreneurship Fee Payment
      </div> */}
      {requestInfo?.request_status ===
        appConstantsUGradOPortal?.GENERATE_ENT_FEE_PENDING && (
        <>
          <LoadingItem />
        </>
      )}
      {requestInfo?.request_status ===
        appConstantsUGradOPortal?.GENERATE_ENT_FEE_SUCCESS && (
        <PaymentDetails pageProps={pageProps} />
      )}

      {requestInfo?.request_status ===
        appConstantsUGradOPortal?.GENERATE_ENT_FEE_FAILURE && (
        <>
          <div className="justify-center">
            <AlertMsg type="error" message={requestInfo?.request_data.error} />
          </div>
          <div className="justify-center ">
            <Link to="/undergraduate/dashboard" className="btn">
              Back
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

const UGradEntrePreneurFeeWrap = (pageProps: any) => {
  return (
    <UndergraduatePortal
    title="Transaction Summary For Entrepreneurship Fee Payment"
      childComponent={<PaymentWrap pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  getUnderGEntrepreneurFee: uGradPortal.UnderGradGetEntrepreneurFee,
};
const mapStateToProps = (state: any) => ({
  UnderGradEntrepreneurFeeRequest:
    state.allUGradPortalReducers.UGradEntrepreneurFee,
  uGradLoginRequest: state.allOnboardingReducers.uGradLogin,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UGradEntrePreneurFeeWrap);
