import { ApiService } from "../../../../services/apiService";
import { routes } from "../../../../services/paths";
import { appConstantsUGradAdmisssions } from "../../../action-constants/admissions/undergraduate";

import { Dispatch } from 'redux';


import {
    handleRequestErrors,
} from "../../../../shared/utils";

const dlLink = "https://eduportalprodstg.blob.core.windows.net/eduportal-prod-ibbu-container/POST_UTME_INSTRUCTION/MessageToAllCandidates.pdf";

const CheckCurrentSession = (actionPayload: any, isPostUme: boolean) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {
            let url = "";

            if (isPostUme) {
                url = routes.GET_CURRENT_POSTUTME_SESSION;
            } else {
                url = routes.GET_CURRENT_SESSION;
            }

            let apiConsume2 = ApiService.request({ url, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume2));
            return apiConsume2
                .then((response2: any) => {

                    dispatch(success(response2.data))


                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradAdmisssions.CHECK_CURRENT_SESSION_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradAdmisssions.CHECK_CURRENT_SESSION_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradAdmisssions.CHECK_CURRENT_SESSION_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradAdmisssions.CHECK_CURRENT_SESSION_RESET, clear_data: "" };
    }
}

const CheckMyAdmissionStatusOne = (actionPayload: any, isPostUme: boolean) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {
            let url = "";

            if (isPostUme) {
                url = routes.UNDERGRAD_CHECK_POSTUME_STATUS;
            } else {
                url = routes.UNDERGRAD_CHECK_ADMISSION_STATUS;
            }

            let apiConsume = ApiService.request({ url, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if(!response?.data?.isAdmitted){
                    //     dispatch(failure(response?.data?.notApprovedReason))
                    // }else{
                    // dispatch(success(response.data))
                    // }

                    let apiConsume2 = ApiService.request({ url: `${routes.GET_CURRENT_SESSION}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
                    dispatch(request(apiConsume2));
                    return apiConsume2
                        .then((response2: any) => {
                            // if(!response2?.data?.isAdmitted){
                            //     dispatch(failure(response2?.data?.notApprovedReason))
                            // }else{
                            if (isPostUme) {
                                if (response?.status == 202) {
                                    let responseData = {
                                        message: "Your O'Level is still undergoing verification, kindly check back on the 27th of October, 2024.",
                                        shouldDownload: true,
                                        dlLink,
                                        ...response.data
                                    }
                                    dispatch(success(responseData))
                                } else {
                                    if (response.data.problems.indexOf("RESULT VERIFICATION REPORT UNDER REVIEW") > -1) {
                                        let responseData = {
                                            message: "Your results verification report is under review, kindly check back on the 20th of October, 2024",
                                            shouldDownload: true,
                                            dlLink,
                                            ...response.data

                                        }
                                        dispatch(success(responseData))
                                    } else if (response.data.problems[0].indexOf("FALSIFICATION OF RESULT") > -1) {
                                        let responseData = {
                                            message: `Disqualification ${response.data.problems[0]}`,
                                            showRed: true,
                                            ...response.data

                                        }
                                        dispatch(success(responseData))
                                    }
                                    else if (response.data.problems.indexOf("YOU ARE NO LONGER A CANDIDATE OF IBBU") > -1) {
                                        dispatch(failure("YOU ARE NO LONGER A CANDIDATE OF IBBU"));
                                    } else {
                                        dispatch(success(response.data))
                                    }
                                }
                            } else {
                                let admissionData = {
                                    sessionData: response2.data,
                                    ...response.data
                                }
                                dispatch(success(admissionData))
                            }

                            // }

                        })
                        .catch((error: any) => {
                            dispatch(failure(handleRequestErrors(error)));
                        });


                })
                .catch((error: any) => {
                    if (error?.response?.status === 404) {
                        if (isPostUme) {
                            dispatch(failure("Sorry, you were not eligible to apply for the IBBUL POST UTME or did not apply to IBBU"));            
                        } else {
                            dispatch(failure("We are sorry! You have not been offered admission yet. Please check back later."));
                        }
                    } else {
                        dispatch(failure(handleRequestErrors(error)));
                    }
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_RESET, clear_data: "" };
    }
}

const CheckMyAdmissionStatus = (actionPayload: any, isPostUme: boolean) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {
            let url = "";

            if (isPostUme) {
                url = routes.UNDERGRAD_CHECK_POSTUME_STATUS;
            } else {
                url = routes.UNDERGRAD_CHECK_ADMISSION_STATUS;
            }

            let apiConsume = ApiService.request({ url, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if(!response?.data?.isAdmitted){
                    //     dispatch(failure(response?.data?.notApprovedReason))
                    // }else{
                    // dispatch(success(response.data))
                    // }

                    let apiConsume2 = ApiService.request({ url: `${routes.GET_CURRENT_SESSION}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
                    dispatch(request(apiConsume2));
                    return apiConsume2
                        .then((response2: any) => {
                            // if(!response2?.data?.isAdmitted){
                            //     dispatch(failure(response2?.data?.notApprovedReason))
                            // }else{
                            if (isPostUme) {
                                if (response?.status == 202) {
                                    let responseData = {
                                        message: "Your O'Level is still undergoing verification, kindly check back on the Monday 18th November, 2024.",
                                        shouldDownload: true,
                                        dlLink,
                                        ...response.data
                                    }
                                    dispatch(success(responseData))
                                } else {
                                    // if (response.data.problems.indexOf("RESULT VERIFICATION REPORT UNDER REVIEW") > -1) {
                                    //     let responseData = {
                                    //         message: "Your results verification report is under review, kindly check back on the 20th of October, 2024",
                                    //         shouldDownload: true,
                                    //         dlLink,
                                    //         ...response.data

                                    //     }
                                    //     dispatch(success(responseData))
                                    // } else 
                                    if (response.data.problems[0].indexOf("FALSIFICATION OF RESULT") > -1) {
                                        let responseData = {
                                            message: `Disqualification ${response.data.problems[0]}`,
                                            showRed: true,
                                            ...response.data

                                        }
                                        dispatch(success(responseData))
                                    }
                                    else if (response.data.problems.indexOf("YOU ARE NO LONGER A CANDIDATE OF IBBU") > -1) {
                                        dispatch(failure("YOU ARE NO LONGER A CANDIDATE OF IBBU"));
                                    } else {
                                        dispatch(success(response.data))
                                    }
                                }
                            } else {
                                let admissionData = {
                                    sessionData: response2.data,
                                    ...response.data
                                }
                                dispatch(success(admissionData))
                            }

                            // }

                        })
                        .catch((error: any) => {
                            dispatch(failure(handleRequestErrors(error)));
                        });


                })
                .catch((error: any) => {
                    if (error?.response?.status === 404) {
                        if (isPostUme) {
                            dispatch(failure("Sorry, you were not eligible to apply for the IBBUL POST UTME or did not apply to IBBU"));
                            // dispatch(failure(error?.response?.data?.message));
                        } else {
                            dispatch(failure("We are sorry! You have not been offered admission yet. Please check back later."));
                        }
                    } else {
                    
                        dispatch(failure(handleRequestErrors(error)));
                    }
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_RESET, clear_data: "" };
    }
}

const PrintMyAcceptanceInvoice = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_PRINT_ACCEPTANCE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    if (response?.data?.downloadUrl) {

                        dispatch(success(response.data))
                    } else {
                        dispatch(failure("Something went wrong. Please try again"));
                    }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_RESET, clear_data: "" };
    }
}

const PrintMySchoolFeeInvoice = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_PRINT_SCHOOL_FEE_INVOICE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    if (response?.data?.downloadUrl) {

                        dispatch(success(response.data))
                    } else {
                        dispatch(failure("Something went wrong. Please try again"));
                    }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_RESET, clear_data: "" };
    }
}

const PrintMyAdmissionLetter = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_PRINT_ADMISSION_LETTER}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    if (response?.data?.downloadUrl) {

                        dispatch(success(response.data))
                    } else {
                        dispatch(failure("Something went wrong. Please try again"));
                    }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_RESET, clear_data: "" };
    }
}


export const uGradAdmissions = {
    dlLink,
    CheckCurrentSession,
    CheckMyAdmissionStatus,
    PrintMyAcceptanceInvoice,
    PrintMySchoolFeeInvoice,
    PrintMyAdmissionLetter
}