import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack } from "react-icons/io";
import Modal from "react-bootstrap/Modal";
import { UndergraduatePortal } from "../../../shared/templates/portal/undergraduate";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { AlertMsg } from "../../../shared/components/alert-msg";
import { LoadingItem } from "../../../shared/components/loading";
import { uGradPortal } from "../../../redux/action/portal/undergraduate";
import DeleteIco from "../../../assets/ic_delete.svg";
import RotateIco from "../../../assets/rotate.gif";
import { appConstantsUGradOPortal } from "../../../redux/action-constants/portal/undergraduate";
import "./index.scss";
import { Link } from "react-router-dom";

const getPresetCourseRegRequest = async ({ pageProps, payload }: any) => {
  await pageProps.getPresetCourseReg(payload);
};

const saveCourseRegRequest = async ({ pageProps, payload }: any) => {
  await pageProps.saveCourseReg(payload);
};

// const printCourseRegRequest = async ({ pageProps, payload }: any) => {
//   await pageProps.printCourseReg(payload);
// };

const uGDeletePresetCourseRequest = async ({ pageProps, payload }: any) => {
  await pageProps.deletePresetCourseReg(payload);
};

const ConfirmDeleteCourseModal = ({
  setShowDeleteModal,
  isDeleteModalShown,
  pageProps,
  presetCourseList,
  selectedCourse,
  setPresetCourseList,
}: any) => {
  const [isConfirmDelete, setConfirmDelete] = useState<boolean>(false);
  let deletePresetCourseRequest = pageProps?.UnderGradDeletePresetCourseRequest;
  useEffect(() => {
    if (isConfirmDelete) {
      if (selectedCourse?.uniqueId) {
        let payload = `?courseUniqueId=${selectedCourse?.uniqueId}`;
        uGDeletePresetCourseRequest({
          pageProps,
          payload,
        });
      } else {
        let selectedSubjects: any[] = [...presetCourseList];

        selectedSubjects = selectedSubjects.filter(
          (eachSubject) =>
            eachSubject?.courseCode !== selectedCourse?.courseCode
        );

        setPresetCourseList(selectedSubjects);

        setShowDeleteModal(false);
      }
    }
  }, [isConfirmDelete]);

  useEffect(() => {
    // if (isConfirmDelete) {

    //     let selectedSubjects: any[] = [...presetCourseList];

    //     selectedSubjects = selectedSubjects.filter(eachSubject => eachSubject?.courseCode !== selectedCourse?.courseCode)

    //     setPresetCourseList(selectedSubjects)

    //     setShowDeleteModal(false)
    // }
    if (
      deletePresetCourseRequest?.request_status ==
      appConstantsUGradOPortal.DELETE_PRESET_COURSE_REG_SUCCESS
    ) {
      let selectedSubjects: any[] = [...presetCourseList];

      selectedSubjects = selectedSubjects.filter(
        (eachSubject) => eachSubject?.courseCode !== selectedCourse?.courseCode
      );

      setPresetCourseList(selectedSubjects);

      // setShowDeleteModal(false)
    }
  }, [deletePresetCourseRequest?.request_status]);

  // setAllChosenCoursesSubjects()
  return (
    <div className="">
      <Modal
        className="delete_modal"
        show={isDeleteModalShown}
        onHide={() => {
          if (!deletePresetCourseRequest?.is_request_processing) {
            uGDeletePresetCourseRequest({
              pageProps,
              payload: "CLEAR",
            });
            setShowDeleteModal(false);
          }
        }}
      >
        <Modal.Body>
          {deletePresetCourseRequest?.request_status !==
            appConstantsUGradOPortal?.DELETE_PRESET_COURSE_REG_SUCCESS && (
            <>
              <div className="delete_confirmation_message">
                Please confirm you want to remove{" "}
                <span>
                  ({selectedCourse?.courseCode}) {selectedCourse?.courseTitle}{" "}
                </span>{" "}
                from your <span>list of courses</span> to register
              </div>

              {deletePresetCourseRequest?.request_status ==
                appConstantsUGradOPortal?.DELETE_PRESET_COURSE_REG_FAILURE && (
                <div className="w-50-a">
                  <AlertMsg
                    type="error"
                    message={deletePresetCourseRequest?.request_data.error}
                  />
                </div>
              )}
              <div className="cta_actions">
                <button
                  disabled={deletePresetCourseRequest?.is_request_processing}
                  className="btn cancel"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </button>
                <button
                  disabled={deletePresetCourseRequest?.is_request_processing}
                  className="btn confirm"
                  onClick={() => setConfirmDelete(true)}
                >
                  {deletePresetCourseRequest?.is_request_processing
                    ? "Deleting Course..."
                    : "Confirm"}
                </button>
              </div>
            </>
          )}
          {deletePresetCourseRequest?.request_status ==
            appConstantsUGradOPortal?.DELETE_PRESET_COURSE_REG_SUCCESS && (
            <div className="">
              <AlertMsg
                type="success"
                message={`${selectedCourse?.courseTitle} (${selectedCourse?.courseCode}) has been removed from your saved courses.`}
              />

              <div className="cta_actions">
                <button
                  className="btn confirm"
                  onClick={() => {
                    uGDeletePresetCourseRequest({
                      pageProps,
                      payload: "CLEAR",
                    });
                    setShowDeleteModal(false);
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

const updateCoursesToRegister = (
  presetCourseList: any[],
  selectedCourse: any,
  courseAction: string,
  setPresetCourseList: any
) => {
  let coursesList: any[] = [...presetCourseList];
  if (courseAction == "remove") {
    coursesList = coursesList.filter(
      (eachItem: any) => eachItem?.courseCode !== selectedCourse?.courseCode
    );
    setPresetCourseList(coursesList);
  }

  if (courseAction == "add") {
    if (
      coursesList.findIndex(
        (eachSubject: any) =>
          eachSubject?.courseCode == selectedCourse?.courseCode &&
          eachSubject?.courseTitle == selectedCourse?.courseTitle
      ) == -1
    ) {
      coursesList.push(selectedCourse);
      setPresetCourseList(coursesList);
    }
  }
};

const RenderEachCourse = ({
  courseData,
  courseToDisplay,
  presetCourseList,
  setPresetCourseList,
  profile,
  pageProps,
}: any) => {
  const [modeSelected, setModeSelected] = useState<any>("");
  const [isCourseAdded, setIsCourseAdded] = useState<any>("no");

  let isCourseExisting =
    presetCourseList.findIndex(
      (eachSubject: any) =>
        eachSubject?.courseCode == courseData?.courseCode &&
        eachSubject?.courseTitle == courseData?.courseName
    ) > -1;
  let loadedCourses: any[] = [...presetCourseList];

  return (
    <tr key={`new_mode-${courseData?.courseUniqueId}`}>
      <td>
        {/* <div data-tooltip-id="mode-tooltip" data-tooltip-content="select if coourse is core/elective!"> </div> */}
        <div
          className="code_tick"
          // title={modeSelected ? "" : "select if coourse is core/elective"}
        >
          {courseData?.courseCode}
        </div>
      </td>
      <td>
        <div className="course_title">{courseData?.courseName}</div>
      </td>

      <td>
        <div className="course_title">{courseData?.courseUnit}</div>
      </td>
      <td>
        <div className="course_title">{courseData?.level}</div>
      </td>
      <td>
        <div className="course_semester">
          {courseData?.semester.split(" ")[0]}
        </div>
      </td>

      <td>
        <div className="add_btn">
          {isCourseExisting && <div className="existing">Added already</div>}
          {!isCourseExisting && (
            <div className="core_elective">
              <select
                key={`mode-${courseData?.courseUniqueId}`}
                value={modeSelected}
                name={`name-${courseData?.courseUniqueId}`}
                id={`id-${courseData?.courseUniqueId}`}
                onChange={(e: any) => {
                  if (e.target.value) {
                    setModeSelected(e.target.value);
                  }
                }}
              >
                <option value="">Choose</option>
                <option value="Core">Core</option>
                <option value="Elective">Elective</option>
              </select>
              <button
                className="btn"
                disabled={!modeSelected}
                onClick={() => {
                  if (modeSelected) {
                    let { courseUniqueId, courseName, ...rest } = courseData;

                    let courseInfo = {
                      ...rest,
                      courseTitle: courseName,
                      department: profile?.department,
                      faculty: profile?.faculty,
                      isCore:
                        modeSelected.toLowerCase() == "core" ? true : false,
                      isElective:
                        modeSelected.toLowerCase() == "elective" ? true : false,
                      programme: profile?.programme,
                      session: profile?.currentSession,
                      studentsLevel: profile?.level,
                    };

                    updateCoursesToRegister(
                      loadedCourses,
                      courseInfo,
                      "add",
                      setPresetCourseList
                    );
                  }
                }}
              >
                Add
              </button>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

const RenderSummary = ({
  allCourses,
  setProceedWithSavedCourses,
  hasRegisteredBefore,
  profile,
  pageProps,
}: any) => {
  const [isConfirmedSavedCourses, setConfirmSavedCourses] =
    useState<boolean>(false);

  useEffect(() => {
    if (isConfirmedSavedCourses) {
      let coursePreSelectViewModels: any[] = allCourses.map((eachItem: any) => {
        let { studentsLevel, uniqueId, semester, ...rest } = eachItem;
        let eachCourse = {
          // ...rest,
          courseCode: eachItem?.courseCode,
          courseUnit: eachItem?.courseUnit,
          courseTitle: eachItem?.courseTitle || eachItem?.courseName,
          isCore: eachItem?.isCore,
          isElective: eachItem?.isElective,
          level: eachItem?.level
            ? eachItem?.level.toString()
            : eachItem?.courseLevel,
          session: eachItem?.session,
          semester: eachItem?.semester || eachItem?.courseSemester,
          studentId: profile?.studentUniqueId,
          programme: profile?.programme,
          faculty: profile?.faculty,
          department: profile?.department,
          matricNumber: profile?.matricNumber,
          createdBy: profile?.matricNumber,
          studentLevel: profile?.level,
          uniqueId: eachItem?.uniqueId || "",
        };

        return {
          ...eachCourse,
        };
      });

      let payload = {
        session: profile?.currentSession,
        programme: profile?.programme,
        studentId: profile?.studentUniqueId,
        matricNumber: profile?.matricNumber,
        studentLevel: profile?.level,
        coursePreSelectViewModels,
      };

      saveCourseRegRequest({
        pageProps,
        payload,
      });
      setConfirmSavedCourses(false);
    }
  }, [isConfirmedSavedCourses]);

  let firstSemester: any[] = [];
  let secondSemester: any[] = [];

  allCourses.forEach((eachCourse: any) => {
    if (
      eachCourse?.semester === "FIRST SEMESTER" ||
      eachCourse?.courseSemester === "FIRST SEMESTER"
    ) {
      firstSemester.push(eachCourse);
    }
    if (
      eachCourse?.semester === "SECOND SEMESTER" ||
      eachCourse?.courseSemester === "SECOND SEMESTER"
    ) {
      secondSemester.push(eachCourse);
    }
  });
  return (
    <div className="courses_summary">
      <div className="header_msg">
        Confirm the courses you want to register for the{" "}
        {profile?.currentSession} session
      </div>

      <div className="semester_head">First Semester Courses</div>
      <Table hover>
        <thead>
          <tr>
            <th> Code</th>
            <th>Course Title</th>
            <th>Unit</th>
            <th>Semester</th>
            <th>Core/Elective</th>
          </tr>
        </thead>
        <tbody>
          {firstSemester.map((eachCourse: any, index: any) => {
            return (
              <tr key={index}>
                <td>{eachCourse?.courseCode}</td>
                <td>{eachCourse?.courseTitle}</td>
                <td>{eachCourse?.courseUnit}</td>
                <td>
                  {eachCourse?.semester && eachCourse?.semester.split(" ")[0]}
                  {eachCourse?.courseSemester &&
                    eachCourse?.courseSemester.split(" ")[0]}
                </td>
                <td>
                  {eachCourse?.isCore ? "Core" : ""}
                  {eachCourse?.isElective ? "Elective" : ""}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="semester_head"> Second Semester Courses</div>
      <Table hover>
        <thead>
          <tr>
            <th> Code</th>
            <th>Course Title</th>
            <th>Unit</th>
            {/* <th>Level</th> */}
            <th>Semester</th>
            <th>Core/Elective</th>
          </tr>
        </thead>
        <tbody>
          {secondSemester.map((eachCourse: any, index: any) => {
            return (
              <tr key={index}>
                <td>{eachCourse?.courseCode}</td>
                <td>{eachCourse?.courseTitle}</td>
                <td>{eachCourse?.courseUnit}</td>
                {/* <td>{eachCourse?.level}</td> */}
                <td>
                  {eachCourse?.semester && eachCourse?.semester.split(" ")[0]}
                  {eachCourse?.courseSemester &&
                    eachCourse?.courseSemester.split(" ")[0]}
                </td>
                <td>
                  {eachCourse?.isCore ? "Core" : ""}
                  {eachCourse?.isElective ? "Elective" : ""}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="proceed_with_cta">
        <button
          className="btn"
          disabled={
            pageProps?.UGradSavePresetCoursesRequest?.is_request_processing
          }
          onClick={() => {
            setConfirmSavedCourses(true);
          }}
        >
          {pageProps?.UGradSavePresetCoursesRequest?.is_request_processing
            ? "Registering your courses..."
            : "Proceed"}
        </button>
      </div>
      {!pageProps?.UGradSavePresetCoursesRequest?.is_request_processing && (
        <div className="goback_cta">
          <button
            className="btn"
            onClick={() => {
              saveCourseRegRequest({
                pageProps,
                payload: "CLEAR",
              });
              setProceedWithSavedCourses(false);
              setConfirmSavedCourses(false);
            }}
          >
            Back
          </button>
        </div>
      )}
    </div>
  );
};

const AddMoreCourses = ({
  allCourses,
  courseToDisplay,
  setCourseToDisplay,
  presetCourseList,
  setPresetCourseList,
  isSpillOver,
  profile,
}: any) => {
  const [searchedSubject, setSearchedSubject] = useState<string>("");
  let coursesList = allCourses;
  let shownCourses: any[] = courseToDisplay;
  useEffect(() => {
    if (searchedSubject.trim().length >= 3) {
      let subjectsList = coursesList;
      let searchResults: any[] = [];

      subjectsList.forEach((eachSubject: any) => {
        if (
          eachSubject?.courseName
            .toLowerCase()
            .includes(searchedSubject.toLowerCase()) ||
          eachSubject?.courseCode
            .toLowerCase()
            .includes(searchedSubject.toLowerCase())
        ) {
          searchResults.push(eachSubject);
        }
      });
      setCourseToDisplay(searchResults);
    }
    if (searchedSubject.trim().length == 0) {
      setCourseToDisplay([]);
    }
  }, [searchedSubject]);

  let itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = shownCourses.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(shownCourses.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % shownCourses.length;

    setItemOffset(newOffset);
  };



  return (
    <div className="add_more_courses">
      <div className="add_more_header">
        <div className="head_txt">{!isSpillOver && "Add more courses"}</div>
        <div className="head_txt">{isSpillOver && presetCourseList.length>=1  && "Add more courses"}</div>
        <div className="head_txt">{isSpillOver && presetCourseList.length==0  && "Add Your courses"}</div>
        <div className="search_course">
          <input
            type="text"
            placeholder="Search for course title, course code"
            value={searchedSubject}
            onChange={(e: any) => {
              setSearchedSubject(e.target.value);
            }}
          />
        </div>
      </div>
      {currentItems.length >= 1 && (
        <div className="course_search_list">
          <Table hover>
            <thead>
              <tr>
                <th> Code</th>
                <th>Course Title</th>
                <th>Unit</th>
                {/* <th>Core/Elective</th> */}
                <th>Level</th>
                <th>Semester</th>
                <th>Core/Elective</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((eachCourse: any, index) => {
                return (
                  <RenderEachCourse
                    key={`item-${index}-${eachCourse?.courseUniqueId}`}
                    courseData={eachCourse}
                    courseToDisplay={courseToDisplay}
                    allCourses={allCourses}
                    presetCourseList={presetCourseList}
                    setPresetCourseList={setPresetCourseList}
                    profile={profile}
                  />
                );
              })}
            </tbody>
          </Table>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            className="pagination_items"
            pageClassName="page_num"
            pageLinkClassName="page_link"
            activeClassName="active_page_link"
            previousClassName="previous_page_link"
            nextClassName="next_page_link"
          />
        </div>
      )}
    </div>
  );
};

const CoursesDetails = ({ pageProps }: any) => {
  const screenResolution: any = useWindowSize();
  let requestInfo =
    pageProps.UGradLoadPresetCoursesRequest?.request_data?.response;
  let regCoursesRequest = pageProps.UGradSavePresetCoursesRequest;

  const [courseToDisplay, setCourseToDisplay] = useState<any[]>([]);
  const [presetCourseList, setPresetCourseList] = useState<any[]>([
    ...requestInfo?.presetCourseList,
  ]);
  const [isDeleteModalShown, setShowDeleteModal] = useState<boolean>(false);
  const [selectedCourse, setCourseToDelete] = useState<any>();
  const [isProceedWithSavedCourses, setProceedWithSavedCourses] =
    useState<boolean>(false);
  const [canEditCourseReg, setCanEditCourseReg] = useState<boolean>(
    requestInfo?.currentSession?.isCourseRegistrationOpen
  );
  const [canStartEditing, setStartEditing] = useState<boolean>(false);
  const [hasRegisteredBefore, setHasRegisteredBefore] = useState<any>(
    requestInfo?.registeredCourses.length >= 1
  );
  let printRequest = pageProps?.UnderGradPrintCourseRequest;

  let firstSemester: any[] = [];
  let secondSemester: any[] = [];

  useEffect(() => {
    if (requestInfo?.registeredCourses.length >= 1) {
      setPresetCourseList(requestInfo?.registeredCourses);
    }

    if (requestInfo?.registeredCourses.length === 0) {
      setPresetCourseList(requestInfo?.presetCourseList);
    }
    if (canEditCourseReg) {
      setStartEditing(true);
    }
  }, []);

  let firstSemestertotalUnits = 0;
  let secondSemestertotalUnits = 0;

  presetCourseList.forEach((eachCourse: any) => {
    if (
      eachCourse?.semester === "FIRST SEMESTER" ||
      eachCourse?.courseSemester === "FIRST SEMESTER"
    ) {
      firstSemester.push(eachCourse);
      firstSemestertotalUnits += eachCourse?.courseUnit;
    }
    if (
      eachCourse?.semester === "SECOND SEMESTER" ||
      eachCourse?.courseSemester === "SECOND SEMESTER"
    ) {
      secondSemester.push(eachCourse);
      secondSemestertotalUnits += eachCourse?.courseUnit;
    }
  });

  // totalUnits += eachCourse?.courseUnit;


  return (
    <div className="content_details">
      {regCoursesRequest?.request_status !==
        appConstantsUGradOPortal?.SAVE_STUDENT_COURSES_REG_SUCCESS && (
        <>
          {!canEditCourseReg && (
            <div className="justify-center">
              <AlertMsg
                type="error"
                message="Course registration for this session has closed"
              />
            </div>
          )}
          {!isProceedWithSavedCourses &&
            (requestInfo?.presetCourseList.length >= 1 || requestInfo?.isSpillOver) && (
              <>
                {(presetCourseList.length >= 1 || requestInfo?.isSpillOver) &&
                  (canStartEditing || hasRegisteredBefore == false) &&
                  canEditCourseReg && (
                    <AddMoreCourses
                      allCourses={requestInfo?.allSubjectsList}
                      profile={requestInfo?.profile}
                      setCourseToDisplay={setCourseToDisplay}
                      courseToDisplay={courseToDisplay}
                      presetCourseList={presetCourseList}
                      setPresetCourseList={setPresetCourseList}
                      isSpillOver ={requestInfo?.isSpillOver}
                    />
                  )}
                {hasRegisteredBefore && canEditCourseReg && (
                  <div className="existing_reg_header">
                    <div className="head_txt">
                      These are the courses you have registered for the{" "}
                      <span>{requestInfo?.profile?.currentSession}</span>{" "}
                      session{" "}
                    </div>
                  </div>
                )}

                {hasRegisteredBefore &&
                  !canStartEditing &&
                  canEditCourseReg && (
                    <div className="edit_courses_cta">
                      <button
                        className="btn"
                        onClick={() => setStartEditing(true)}
                      >
                        Edit your Course Registration
                      </button>
                    </div>
                  )}

                {isDeleteModalShown && (
                  <ConfirmDeleteCourseModal
                    pageProps={pageProps}
                    selectedCourse={selectedCourse}
                    setPresetCourseList={setPresetCourseList}
                    setShowDeleteModal={setShowDeleteModal}
                    isDeleteModalShown={isDeleteModalShown}
                    presetCourseList={presetCourseList}
                  />
                )}

                <>
                  {canEditCourseReg && (
                    <>
                      {firstSemester.length >= 1 && (
                        <div className="course_list">
                          <div className="semester_heading">
                            Your First Semester Courses to Register
                          </div>
                          <div className="semester_wrap">
                            {screenResolution?.width >= 600 && (
                              <table>
                                <thead>
                                  <tr>
                                    <th className="first">
                                      <span> COURSE TITLE</span>
                                    </th>
                                    <th>
                                      {" "}
                                      <span>CODE</span>
                                    </th>
                                    <th>
                                      {" "}
                                      <span>UNIT</span>
                                    </th>
                                    <th>
                                      {" "}
                                      <span>CORE/ELECTIVE</span>
                                    </th>

                                    <th>
                                      {" "}
                                      <span>SEMESTER</span>
                                    </th>
                                    <th>
                                      {" "}
                                      <span>SESSION</span>
                                    </th>
                                    {(canStartEditing ||
                                      hasRegisteredBefore == false) && (
                                      <th className="last">
                                        <span>DELETE</span>
                                      </th>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {firstSemester.map(
                                    (courseData: any, index: any) => {
                                      return (
                                        <tr key={index}>
                                          <td className="first">
                                            {courseData?.courseTitle}
                                            {/* <span>{courseData?.courseTitle}</span> */}
                                          </td>
                                          <td>
                                            {courseData?.courseCode}
                                            {/* <span>{courseData?.courseCode}</span> */}
                                          </td>
                                          <td>
                                            {courseData?.courseUnit}
                                            {/* <span>{courseData?.courseUnit}</span>  */}
                                            {/* <span>{courseData?.courseUnit}</span>  */}
                                          </td>
                                          <td>
                                            {courseData?.isCore ? "Core" : ""}
                                            {courseData?.isElective
                                              ? "Elective"
                                              : ""}
                                            {/* <span>
                                                                {courseData?.isCore ? "Core" : ""}
                                                                {courseData?.isElective ? "Elective" : ""}
                                                            </span> */}
                                          </td>

                                          <td>
                                            {courseData?.semester &&
                                              courseData?.semester.split(
                                                " "
                                              )[0]}
                                            {courseData?.courseSemester &&
                                              courseData?.courseSemester.split(
                                                " "
                                              )[0]}
                                            {/* <span>{courseData?.semester.split(" ")[0]}</span> */}
                                          </td>
                                          <td>
                                            {courseData?.session}
                                            {/* <span>{courseData?.session}</span>  */}
                                          </td>
                                          {(canStartEditing ||
                                            hasRegisteredBefore == false) && (
                                            <td className="last">
                                              <div className="action_elem">
                                                <button
                                                  className="btn delete_course"
                                                  onClick={() => {
                                                    setCourseToDelete(
                                                      courseData
                                                    );
                                                    setShowDeleteModal(true);
                                                  }}
                                                >
                                                  DELETE
                                                  {/* <img src={DeleteIco} alt="" /> */}
                                                </button>
                                              </div>
                                            </td>
                                          )}
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="course_unit_msg">
                        <div>
                          {" "}
                          Maximum Total course units allowed for First Semester
                          is : <span>{requestInfo?.maxCourses?.firsSemesterMaxUnit}</span>
                        </div>
                        <div>
                          Total Unit of Courses you have for First Semester is:{" "}
                          <span>{firstSemestertotalUnits}</span>
                        </div>
                      </div>

                      {secondSemester.length >= 1 && (
                        <div className="course_list">
                          <div className="semester_heading">
                            Your Second Semester Courses to Register
                          </div>
                          <div className="semester_wrap">
                            {screenResolution?.width >= 600 && (
                              <table>
                                <thead>
                                  <tr>
                                    <th className="first">
                                      <span> COURSE TITLE</span>
                                    </th>
                                    <th>
                                      {" "}
                                      <span>CODE</span>
                                    </th>
                                    <th>
                                      {" "}
                                      <span>UNIT</span>
                                    </th>
                                    <th>
                                      {" "}
                                      <span>CORE/ELECTIVE</span>
                                    </th>

                                    <th>
                                      {" "}
                                      <span>SEMESTER</span>
                                    </th>
                                    <th>
                                      {" "}
                                      <span>SESSION</span>
                                    </th>
                                    {(canStartEditing ||
                                      hasRegisteredBefore == false) && (
                                      <th className="last">
                                        <span>DELETE</span>
                                      </th>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {secondSemester.map(
                                    (courseData: any, index: any) => {
                                      return (
                                        <tr key={index}>
                                          <td className="first">
                                            {courseData?.courseTitle}
                                            {/* <span>{courseData?.courseTitle}</span> */}
                                          </td>
                                          <td>
                                            {courseData?.courseCode}
                                            {/* <span>{courseData?.courseCode}</span> */}
                                          </td>
                                          <td>
                                            {courseData?.courseUnit}
                                            {/* <span>{courseData?.courseUnit}</span>  */}
                                            {/* <span>{courseData?.courseUnit}</span>  */}
                                          </td>
                                          <td>
                                            {courseData?.isCore ? "Core" : ""}
                                            {courseData?.isElective
                                              ? "Elective"
                                              : ""}
                                            {/* <span>
                                                                    {courseData?.isCore ? "Core" : ""}
                                                                    {courseData?.isElective ? "Elective" : ""}
                                                                </span> */}
                                          </td>

                                          <td>
                                            {courseData?.semester &&
                                              courseData?.semester.split(
                                                " "
                                              )[0]}
                                            {courseData?.courseSemester &&
                                              courseData?.courseSemester.split(
                                                " "
                                              )[0]}
                                            {/* <span>{courseData?.semester.split(" ")[0]}</span> */}
                                          </td>
                                          <td>
                                            {courseData?.session}
                                            {/* <span>{courseData?.session}</span>  */}
                                          </td>
                                          {(canStartEditing ||
                                            hasRegisteredBefore == false) && (
                                            <td className="last">
                                              <div className="action_elem">
                                                <button
                                                  className="btn delete_course"
                                                  onClick={() => {
                                                    setCourseToDelete(
                                                      courseData
                                                    );
                                                    setShowDeleteModal(true);
                                                    // updateCoursesToRegister(
                                                    //     presetCourseList,
                                                    //     courseData,
                                                    //     "remove",
                                                    //     setPresetCourseList
                                                    // )
                                                  }}
                                                >
                                                  DELETE
                                                  {/* <img src={DeleteIco} alt="" /> */}
                                                </button>
                                              </div>
                                            </td>
                                          )}
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            )}

                            {/* {screenResolution?.width <= 766 &&
                            <div className="past_data">
                                {
                                    requestInfo?.compulsoryFeeHistories.map((txtn: any, index: any) => {
                                        return (
                                            <div className="each_history_data">
                                                <div className="top_data">
                                                    <div className="left"> <span>Generated on:</span> {txtn?.generatedOn}</div>
                                                    <div className="right"> <span className={txtn?.paymentStatus.toLowerCase() == "paid" ? "done status" : "pending status"}>{txtn?.paymentStatus}</span></div>
                                                </div>
                                                <div className="other_data">
                                                    <div className="left">
                                                        <div className="title">Amount</div>
                                                        <div className="value">{txtn?.amount}</div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="title">Transaction ID</div>
                                                        <div className="value">{txtn?.transactionId}</div>
                                                    </div>

                                                </div>
                                                <div className="other_data">
                                                    <div className="left">
                                                        <div className="title">Session</div>
                                                        <div className="value">{txtn?.session}</div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="value">
                                                            <a href={txtn?.downloadUrl} className="download_cta btn">Download</a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        } */}
                          </div>
                        </div>
                      )}

                      <div className="course_unit_msg">
                        <div>
                          {" "}
                          Maximum Total course units allowed for Second Semester
                          is : <span>{requestInfo?.maxCourses?.secondSemesterMaxUnit}</span>
                        </div>
                        <div>
                          Total Unit of Courses you have for Second Semester is:{" "}
                          <span>{secondSemestertotalUnits}</span>
                        </div>
                      </div>
                      {secondSemestertotalUnits >
                        requestInfo?.maxCourses?.secondSemesterMaxUnit && (
                        <div className="justify-center">
                          <AlertMsg
                            type="error"
                            message={`To ensure a successful submission, your course selection for Second Semester needs to be adjusted to fit within the ${requestInfo?.maxCourses?.secondSemesterMaxUnit} unit limit`}
                          />
                        </div>
                      )}
                      {firstSemestertotalUnits >
                        requestInfo?.maxCourses?.firsSemesterMaxUnit && (
                        <div className="justify-center">
                          <AlertMsg
                            type="error"
                            message={`To ensure a successful submission, your course selection for First Semester needs to be adjusted to fit within the ${requestInfo?.maxCourses?.firsSemesterMaxUnit} unit limit`}
                          />
                        </div>
                      )}
                      {canEditCourseReg &&
                        (canStartEditing || hasRegisteredBefore == false) && (
                          <div className="proceed_with_cta">
                            <button
                              className="btn"
                              onClick={() => {
                                if (
                                  firstSemestertotalUnits <=
                                    requestInfo?.maxCourses?.firsSemesterMaxUnit &&
                                  secondSemestertotalUnits <=
                                    requestInfo?.maxCourses?.secondSemesterMaxUnit
                                ) {
                                  setProceedWithSavedCourses(true);
                                }
                              }}
                            >
                              {hasRegisteredBefore
                                ? "Proceed to Update"
                                : "Proceed"}
                            </button>
                          </div>
                        )}
                    </>
                  )}

                  {!canEditCourseReg && hasRegisteredBefore == false && (
                    <div className="justify-center">
                      <AlertMsg
                        type="error"
                        message={`Course Registrastion has closed. You did not resgiter courses for the session`}
                      />
                    </div>
                  )}
                  {/* {(!canStartEditing) && hasRegisteredBefore && ( */}
                  {/* {hasRegisteredBefore && (
                    <div className="proceed_with_cta mt-30">
                      <button
                        className="btn with-blue"
                        disabled={printRequest?.is_request_processing}
                        onClick={() => {
                          printCourseRegRequest({
                            pageProps,
                            payload: {
                              studentId: requestInfo?.profile?.studentUniqueId,
                              level: requestInfo?.profile?.level,
                              session: requestInfo?.currentSession?.session,
                            },
                          });
                        }}
                      >
                        {printRequest?.is_request_processing
                          ? "Please wait"
                          : "Click here to Print  Registered Courses"}
                      </button>
                    </div>
                  )} */}
                </>

                <div className="goback_cta">
                  <Link to="/undergraduate/dashboard" className="btn">
                    Back
                  </Link>
                </div>
              </>
            )}
          {requestInfo?.presetCourseList.length == 0 && !requestInfo?.isSpillOver && (
            <div className="justify-center">
              <AlertMsg
                type="error"
                message="Your Course Adviser is yet to Upload your courses for this session"
              />
            </div>
          )}
          {isProceedWithSavedCourses && (
            <RenderSummary
              allCourses={presetCourseList}
              profile={requestInfo?.profile}
              pageProps={pageProps}
              setProceedWithSavedCourses={setProceedWithSavedCourses}
              hasRegisteredBefore={hasRegisteredBefore}
            />
          )}
        </>
      )}
      {regCoursesRequest?.request_status ===
        appConstantsUGradOPortal?.SAVE_STUDENT_COURSES_REG_SUCCESS && (
        <div className="courses_loaded_success">
          <div className="success-message-wrap">
            <div className="success-animation">
              <svg
                className="success_icon_tick"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
            <div className="success-message-txt">
              {" "}
              You have succesfully{" "}
              {hasRegisteredBefore ? "updated" : "registered"} your courses for{" "}
              <span>{requestInfo?.profile?.currentSession} session</span>{" "}
            </div>
            <div className="product-cta">
              <a
                href={regCoursesRequest?.request_data?.response}
                className="btn"
                target="_blank"
              >
                Click here to Print
              </a>
            </div>
            <div className="goback_cta">
              <button
                className="btn"
                onClick={() => {
                  saveCourseRegRequest({
                    pageProps,
                    payload: "CLEAR",
                  });
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      )}
      {regCoursesRequest?.request_status ===
        appConstantsUGradOPortal?.SAVE_STUDENT_COURSES_REG_FAILURE && (
        <div className="justify-center">
          <AlertMsg
            type="error"
            message={regCoursesRequest?.request_data.error}
          />
        </div>
      )}
    </div>
  );
};

const CourseDetailsWrap = ({ pageProps }: any) => {
  let authInfo = pageProps.uGradLoginRequest.request_data;
  let requestInfo = pageProps.UGradLoadPresetCoursesRequest;
  const screenResolution: any = useWindowSize();
  useEffect(() => {
    let payload = {
      matricNumber: authInfo?.matricNumber,
    };

    getPresetCourseRegRequest({
      pageProps,
      payload,
    });
  }, []);

  return (
    <>
      {requestInfo?.request_status ===
        appConstantsUGradOPortal?.UGRAD_LOAD_PRESET_COURSES_SUCCESS && (
        <>
          {screenResolution?.width >= 600 && (
            <CoursesDetails pageProps={pageProps} />
          )}
          {screenResolution?.width < 600 && (
            <div className="tilt_phone">
              <img src={RotateIco} alt="" />
            </div>
          )}
        </>
      )}
    </>
  );
};

const CourseRegWrap = ({ pageProps }: any) => {
  let requestInfo = pageProps.UGradLoadPresetCoursesRequest;

  useEffect(() => {
    saveCourseRegRequest({
      pageProps,
      payload: "CLEAR",
    });

    getPresetCourseRegRequest({
      pageProps,
      payload: "CLEAR",
    });
  }, []);
  return (
    <div className="course_reg_wrap">
      {requestInfo?.request_status ===
        appConstantsUGradOPortal?.UGRAD_LOAD_PRESET_COURSES_PENDING && (
        <>
          <LoadingItem />
        </>
      )}

      <CourseDetailsWrap pageProps={pageProps} />

      {/* CourseDetailsWrap */}

      {requestInfo?.request_status ===
        appConstantsUGradOPortal?.UGRAD_LOAD_PRESET_COURSES_FAILURE && (
        <div className="justify-center">
          <AlertMsg type="error" message={requestInfo?.request_data.error} />
        </div>
      )}
    </div>
  );
};

const UGradCourseRegWrap = (pageProps: any) => {
  return (
    <UndergraduatePortal
      title="Course Registration"
      childComponent={<CourseRegWrap pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  getPresetCourseReg: uGradPortal.UnderGradLoadPresetCourseReg,
  saveCourseReg: uGradPortal.UnderGradSaveCourseReg,
  deletePresetCourseReg: uGradPortal.UnderGradDeletePresetCourseReg,
  // printCourseReg: uGradPortal.UnderGradPrintCourseReg,
};
const mapStateToProps = (state: any) => ({
  UGradLoadPresetCoursesRequest:
    state.allUGradPortalReducers.UGradLoadPresetCourses,
  UGradSavePresetCoursesRequest:
    state.allUGradPortalReducers.UGradSavePresetCourses,
  UnderGradDeletePresetCourseRequest:
    state.allUGradPortalReducers.UnderGradDeletePresetCourse,
  UnderGradPrintCourseRequest:
    state.allUGradPortalReducers.UnderGradPrintCourse,
  uGradLoginRequest: state.allOnboardingReducers.uGradLogin,
});

export default connect(mapStateToProps, mapDispatchToProps)(UGradCourseRegWrap);
